.sidebar ul {
    margin: 0;
    padding: 0;
}
#source-selector .tagify {
    --tag-pad: 0.2em;
    height: 31px;
}

#source-selector div.pf-c-form__group {
    --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 2px !important;
}

.tags-look .tagify__dropdown__item{
    display: inline-block;
    border-radius: 3px;
    padding: .3em .5em;
    border: 1px solid #CCC;
    background: #F3F3F3;
    margin: .2em;
    font-size: .85em;
    color: black;
    transition: 0s;
}

.tags-look .tagify__dropdown__item--active{
    color: black;
}

.tags-look .tagify__dropdown__item:hover{
    background: lightyellow;
    border-color: gold;
}

.tags-input tag.tag-loading {
    overflow: hidden;
    border-radius: 16px;
}

.tags-input tag x.tagify__tag__removeBtn {
    z-index: 10;
}

.tags-input tag.tag-loading div {
    width: 30px;
}

.tags-input tag.tag-loading div::after {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     transform: translateX(-100%);
     background-image: linear-gradient(
             90deg,
             rgba(var(--tag-bg-loading), 0) 0,
             rgba(var(--tag-bg-loading), 0.2) 20%,
             rgba(var(--tag-bg-loading) , 0.4) 60%,
             rgba(var(--tag-bg-loading), 0)
     );
     animation: skeleton-loading 2s infinite;
     content: '';
     border-radius: 16px;
    overflow: hidden;
 }

@keyframes skeleton-loading {
    100% {
        transform: translateX(100%);
    }
}
